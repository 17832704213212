const guide = {
    start_building: 'Starten Sie mit dem Aufbau',
    get_started: 'Beginnen Sie',
    categories: {
        featured: 'Beliebt und für dich',
        Traditional: 'Traditionelle Webanwendung',
        SPA: 'Single-Page-Anwendung',
        Native: 'Native',
        MachineToMachine: 'Maschinen-zu-Maschinen',
        Protected: 'Integration ohne SDK',
        ThirdParty: 'Third-party-App',
        SAML: 'SAML-App',
    },
    filter: {
        title: 'Filter-Framework',
        placeholder: 'Nach Framework suchen',
    },
    checkout_tutorial: 'Tutorial von {{name}} anzeigen',
    do_not_need_tutorial: 'Wenn Sie kein Tutorial benötigen, können Sie ohne Anleitung fortfahren',
    finish_and_done: 'Fertig und erledigt',
    cannot_find_guide: 'Guide nicht gefunden?',
    describe_guide_looking_for: 'Beschreiben Sie den Guide, den Sie suchen',
    request_guide_successfully: 'Ihre Anfrage wurde erfolgreich übermittelt. Danke!',
    app: {
        select_framework_or_tutorial: 'Wählen Sie ein Framework oder Tutorial aus',
        guide_modal_title: 'Starten Sie mit dem SDK und den Guides',
        modal_subtitle: 'Beschleunigen Sie Ihre App-Entwicklung mit unserem vorgefertigten SDK und Tutorials.',
        select_a_framework: 'Wählen Sie ein Framework aus',
        continue_without_framework: 'App ohne Framework erstellen',
        describe_guide_looking_for_placeholder: 'Zum Beispiel: Ich möchte Logto in meine Angular-App integrieren.',
    },
    api: {
        modal_title: 'Starten Sie mit Tutorials',
        modal_subtitle: 'Beschleunigen Sie Ihre App-Entwicklung mit unseren vorgefertigten Tutorials.',
        select_a_tutorial: 'Wählen Sie ein Tutorial aus',
        continue_without_tutorial: 'Ohne Tutorial fortfahren',
        describe_guide_looking_for_placeholder: 'Zum Beispiel: Ich möchte meine API mit Deno schützen.',
    },
};
export default Object.freeze(guide);
