const users = {
    page_title: 'Benutzerverwaltung',
    title: 'Benutzerverwaltung',
    subtitle: 'Verwalten von Benutzeridentitäten, einschließlich des Anlegens von Benutzern, Bearbeiten von Benutzerinformationen, Anzeigen von Benutzer-Logs, Zurücksetzen von Passwörtern und Löschen von Benutzern.',
    create: 'Benutzer hinzufügen',
    create_subtitle: 'Um fortzufahren, geben Sie bitte mindestens eines der folgenden Felder an.',
    error_missing_identifier: 'Sie müssen mindestens einen Identifikator eingeben, um einen Benutzer anzulegen',
    user_name: 'Benutzername',
    application_name: 'Anwendungsname',
    latest_sign_in: 'Letzte Anmeldung',
    create_form_username: 'Benutzername',
    create_form_password: 'Passwort',
    create_form_name: 'Name',
    placeholder_name: 'Max Mustermann',
    placeholder_email: 'max@example.com',
    placeholder_username: 'user123',
    placeholder_phone: '+49 (0)123 456789',
    unnamed: 'Unbenannt',
    search: 'Suche nach Name, E-Mail, Telefon oder Benutzername',
    check_user_detail: 'Benutzerdetails überprüfen',
    placeholder_title: 'Benutzerverwaltung',
    placeholder_description: 'Jeder Benutzer hat ein Profil mit allen Benutzerinformationen. Es besteht aus grundlegenden Daten, sozialen Identitäten und benutzerdefinierten Daten.',
};
export default Object.freeze(users);
