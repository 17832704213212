const user_scopes = {
    descriptions: {
        custom_data: 'Deine benutzerdefinierten Daten',
        email: 'Deine E-Mail-Adresse',
        phone: 'Deine Telefonnummer',
        profile: 'Dein Name, Benutzername, Avatar und andere Profilinformationen',
        roles: 'Deine Rollen',
        identities: 'Deine verknüpften sozialen Identitäten',
        'urn:logto:scope:organizations': 'Die Informationen deiner Organisationen',
        'urn:logto:scope:organization_roles': 'Deine Rollen in der Organisation',
        address: 'Deine Adresse',
    },
};
export default Object.freeze(user_scopes);
